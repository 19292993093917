<template>
  <v-list class="mx-list">

    <v-list-item to="/dashboard" v-if="mxPermission('dashboard-show')">
      <v-list-item-action>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-action>
      <v-list-item-title>
        Dashboard
      </v-list-item-title>
    </v-list-item>

    <v-list-item to="/documents" v-if="mxPermission('document-show')">
      <v-list-item-action>
        <v-icon>mdi-folder-outline</v-icon>
      </v-list-item-action>
      <v-list-item-title>
        Dokumente
      </v-list-item-title>
    </v-list-item>


    <!--Users -->
    <v-list-group
        prepend-icon="mdi-account-circle"
        :value="subIsActive('/users')"
        v-if="subPermission ('employee-show', 'customer-show')"
    >
      <template v-slot:activator>
        <v-list-item-title>Users</v-list-item-title>
      </template>
      <v-list-item to="/users/employees" link v-if="mxPermission('employee-show')">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Mitarbeiter</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/users/customers" link v-if="mxPermission('customer-show')">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Kunde</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

    <!--CRM -->
    <v-list-group
        prepend-icon="mdi-desktop-classic"
        :value="subIsActive('/crm')"
        v-if="subPermission ('employee-crm-show', 'customer-crm-show', 'manager-crm-show')"
    >
      <template v-slot:activator>
        <v-list-item-title>CRM</v-list-item-title>
      </template>
      <v-list-item to="/crm-employee" link v-if="mxPermission('employee-crm-show')">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Mitarbeiter CRM</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/crm-customer" link v-if="mxPermission('customer-crm-show')">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Kunde CRM</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/crm-user" link v-if="mxPermission('manager-crm-show')">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Manager CRM</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-list-item to="/notices" v-if="mxPermission('notice-read')">
      <v-list-item-action>
        <v-icon>mdi-note-outline</v-icon>
      </v-list-item-action>
      <v-list-item-title>
        {{ $t('notice.menu.title') }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      default: []
    }
  },

  methods: {
    mxPermission(permission) {
      return this.mxware.can(permission)
    },
    subPermission(...permissions) {
      return this.mxware.subPermission(permissions)
    },
    subIsActive(str) {
      const paths = Array.isArray(str) ? str : [str];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0;
      });
    }
  }
}
</script>

<style>
.mx-list.v-list .v-list-item--active {
  color: #fc8b5d;
}
</style>
