import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
    Vue.config.productionTip = true
    Vue.config.devtools = false;
    Vue.config.debug = false;
    Vue.config.silent = true;
}


import mxware from "@/middleware/access_middleware";
Vue.prototype.mxware = mxware

// Preloader
import Preloader from "@/components/Preloader";
Vue.component('mx-preloader', Preloader)

// SCSS
import './assets/scss/main.scss'

// Options
import options from "./options";
Vue.prototype.options = options;

/* Axios */
require('./store/subscriber');
axios.defaults.withCredentials = false;
axios.defaults.baseURL = options.default_url + '/api/';

// VueInputMask
const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask)

// VueI18n
import {i18n} from './plugins/i18n'

// SSE
import VueSSE from 'vue-sse';
Vue.use(VueSSE);

// WebSocket
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'local',
    // wsHost: 'peepz.api.employee.loc',
    wsHost: (process.env.NODE_ENV === 'production') ? 'employee-api.peepz.net' : 'peepz.api.employee.loc',
    wsPort: 6001,
    forceTLS: false,
    disableStats: true
});


store.dispatch('attempt', localStorage.getItem('token')).then(() => {
    new Vue({
        router,
        store,
        i18n,
        vuetify,
        render: h => h(App)
    }).$mount('#app')
})
