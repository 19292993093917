/* Middleware */
import guest from '@/middleware/guest';

export default [
    {
        path: '/',
        name: 'login',
        meta: {
            layout: 'auth',
            middleware: [guest]
        },
        component: () => import('@/views/auth/LoginView')
    },
    {
        path: '/unlock',
        name: 'unlock',
        meta: {
            layout: 'auth',
            middleware: [guest]
        },
        component: () => import('@/views/auth/UnlockView')
    },
];