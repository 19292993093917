import * as Service from '@/services/laratrust/laratrust_employee_service'

export default {
    namespace: true,
    state: {
        permission_employee: {},
        permissions_employee: [],
        role_employee: {},
        roles_employee: [],
    },
    getters: {
        permission_employee_laratrust(state) {
            return state.permission_employee
        },
        permissions_employee_laratrust(state) {
            return state.permissions_employee
        },
        role_employee_laratrust(state) {
            return state.role_employee
        },
        roles_employee_laratrust(state) {
            return state.roles_employee
        }
    },
    mutations: {
        SET_ROLE_EMPLOYEE_LARATRUST(state, data) {
            state.role_employee = data
        },
        SET_ROLES_EMPLOYEE_LARATRUST(state, data) {
            state.roles_employee = data
        },
        SET_PERMISSION_EMPLOYEE_LARATRUST(state, data) {
            state.permission_employee = data
        },
        SET_PERMISSIONS_EMPLOYEE_LARATRUST(state, data) {
            state.permissions_employee = data
        }
    },
    actions: {
        /* Permissions */
        async permissionsEmployeeLaratrust({commit}) {
            await Service.permissions().then(response => {
                commit('SET_PERMISSIONS_EMPLOYEE_LARATRUST', response.data)
            })
        },

        /* Permission Create */
        async permissionEmployeeCreateLaratrust({commit}, data) {
            await Service.permissionCreate(data).then(response => {
                commit('SET_PERMISSIONS_EMPLOYEE_LARATRUST', response.data)
            })
        },

        /* Permission Update */
        async permissionEmployeeUpdateLaratrust({commit}, data) {
            await Service.permissionUpdate(data).then(response => {
                commit('SET_PERMISSIONS_EMPLOYEE_LARATRUST', response.data)
            })
        },

        /* Permission Delete */
        async permissionEmployeeDeleteLaratrust({commit}, id) {
            await Service.permissionDelete(id).then(response => {
                commit('SET_PERMISSIONS_EMPLOYEE_LARATRUST', response.data)
            })
        },


        /* Roles */
        async rolesEmployeeLaratrust({commit}) {
            await Service.roles().then(response => {
                commit('SET_ROLES_EMPLOYEE_LARATRUST', response.data)
            })
        },

        /* Role create */
        async roleEmployeeCreateLaratrust({commit}, data) {
            await Service.roleCreate(data).then(response => {
                commit('SET_ROLES_EMPLOYEE_LARATRUST', response.data)
            })
        },

        /* Role Show */
        async roleEmployeeShowLaratrust({commit}, id) {
            return await Service.roleShow(id)
        },

        /* Role Edit */
        async roleEmployeeEditLaratrust({commit}, id) {
            return await Service.roleEdit(id)
        },

        /* Role Update */
        async roleEmployeeUpdateLaratrust({commit}, data) {
            return await Service.roleUpdate(data)
        },

        /* Role Delete */
        async roleEmployeeDeleteLaratrust({commit}, id) {
            return await Service.roleDelete(id)
        },

    }
}