/* Middleware */
import auth from '@/middleware/auth';

import customer from './_children/_c_customer_router'
import {show} from '@/middleware/permissions/customer_crm'

export default [
    {
        path: '/crm-customer',
        name: 'crm_customer',
        meta: {
            layout: 'main',
            middleware: [auth, show]
        },
        redirect: '/crm-customer/customers',
        component: () => import('@/views/customer/crm/CrmView'),

        children: [
            ...customer
        ]
    },

];
