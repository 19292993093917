export default {
  "login": "Einlogen",
  "logout": "Auslogen",
  "form": {
    "btn": {
      "login": "Einlogen",
      "logout": "Auslogen",
    },
    "login": {
      "label": {
        "email": "Email",
        "password": "Passwort"
      },
      "required": {
        "email": "E-Mail ist pflichtfeld",
        "password": "Passwort ist pflichtfeld",
      },
      // "error": "Anmeldung mit diesen Daten fehlgeschlagen."
      "error": "E-mail oder Passwort falsch!"
    }

  }
}
