import * as Service from '@/services/auth/auth_service'


export default {
    namespace: true,
    state: {
        token: null,
        auth_permissions: [
            "left-menu-dashboard",
            "left-menu-document",
            "left-menu-employee",
            "left-menu-customer",
            "left-menu-crm-employee",
            "left-menu-crm-customer",
            "left-menu-crm-manager",
        ],
        auth_roles: []
    },
    getters: {
        authenticated(state) {
            return state.token
        },
        auth_permissions(state) {
            return state.auth_permissions
        },
        auth_roles(state) {
            return state.auth_roles
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_AUTH_PERMISSIONS(state, data) {
            state.auth_permissions = data
        },
        SET_AUTH_ROLES(state, data) {
            state.auth_roles = data
        },
        SET_LANGUAGE(state, language) {
            state.language = language
        },
    },
    actions: {

        /* Login */
        async signIn({dispatch}, credentials) {
            let response = await Service.login(credentials)
            await dispatch('attempt', response.data.meta.token)
        },

        /* Logout */
        async signOut({commit, dispatch}) {
            await Service.logout()
            await dispatch('reset')
            location.replace('/')
        },

        /* Attempt */
        async attempt({commit, state, dispatch}, token) {
            if (token) {commit('SET_TOKEN', token)}
            if (!state.token) {return;}

            try {
                await Service.me().then(response => {
                    /* Setup */
                    dispatch('setup', response)

                    /* Websocket */
                    dispatch('websocket')
                })
            } catch (error) {
                commit('SET_TOKEN', null);
                commit('SET_USER', null);
            }
        },


        /* Setup */
        async setup({commit}, response) {
            commit('SET_USER', response.data)
            commit('SET_AUTH_PERMISSIONS', response.data.auth_permission)
            commit('SET_AUTH_ROLES', response.data.auth_role)
            commit('SET_LANGUAGE', response.data.language)

            await sessionStorage.setItem('username', response.data.first_name + ' ' + response.data.last_name);
            await sessionStorage.setItem('email', response.data.email);
        },

        /* Reset */
        async reset({commit}) {
            commit('SET_TOKEN', null)
        },

        /* WEBSOCKET */
        async websocket({commit, state, dispatch}) {

            /* Employees authorized in crm system */
            dispatch('employeesActiveCrm')

            if (state.employees_crm === undefined) {
                dispatch('employeesCrm').then(() => {
                    window.Echo.channel('channel-auth').listen('Login', (e) => {
                        commit('SET_EMPLOYEE_CRM_DASHBOARD', e.id)
                    })
                })
            } else {
                window.Echo.channel('channel-auth').listen('Login', (e) => {
                    commit('SET_EMPLOYEE_CRM_DASHBOARD', e.id)
                })
            }

            window.Echo.channel('channel-auth').listen('Logout', (e) => {
                commit('SET_EMPLOYEE_CRM_DELETE_DASHBOARD', e.id)
            })
        }
    }
}
