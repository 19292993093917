import { http } from "../http_service";

// User Auth
export function login(data) {
    return http().post(`/auth/login`, data);
}

export function logout() {
    return http().post(`/auth/logout`);
}

export function me() {
    return http().get(`/auth/me`);
}