import { http } from "../http_service";


/** EMPLOYEES */
export function employeesCrm(page) {
    return http().get(`/employees-gateway?page=${page}`);
}

export function employeesActiveCrm() {
    return http().get(`/user-authorized`);
}

/** EMPLOYEE SHOW */
export function employeeCrmShow(id) {
    return http().get(`/employees-gateway/${id}`);
}

/** EMPLOYEE SEARCH */
export function employeeCrmSearch(data) {
    return http().post(`/employees-gateway/search`, data);
}

/** EMPLOYEE EDIT */
export function employeeCrmEdit(id) {
    return http().get(`/employees-gateway/${id}/edit`);
}

/** EMPLOYEE CREATE */
export function employeeCrmCreate(data) {
    return http().post(`/employees-gateway`, data);
}

/** EMPLOYEE UPDATE */
export function employeeCrmUpdate(data) {
    return http().put(`/employees-gateway/${data.id}`, data);
}

/** EMPLOYEE DELETE */
export function employeeCrmDelete(id) {
    return http().delete(`/employees-gateway/${id}`);
}
