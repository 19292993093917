export default {
    "not_found": "Noch keinen registrierten Kunden",
    "identification": "Personalnummer",
    "id": "ID",
    "uid": "UID",
    "first_name": "Vorname",
    "last_name": "Nachname",
    "email": "Email",
    "full_name": "Vorname Nachname",
    "birthday": "Geburtsdatum",
    "can_faq": "FAQ erstellen",
    "multi_company": "Mehrere Firmen",
    "user_blocked": "Kunde ist blockiert",
    "is_blocked": "Kunde blockiert",
    "is_deleted": "Kunde gelöscht",
    "email_send": "Email an den Kunden senden?",
    "password": "Passwort",
    "password_confirmation": "Passwort widerholen",
    "company_name": "Firmenname",
    "position": "Position",
    "phone": "Telefonnummer",

    "titles": {
        "personal_data": "Persönlichen daten",
        "role_and_permissions": "Rolle und Berechtigungen",
        "settings": "Einstellungen",
        "password": "Passwort",
        "avatar": "Avatar",
        "customer_delete": "Wollen Sie diese kunde löschen?",
    },

    "buttons": {
        "avatar_create": "Avatar erstellen",
        "password_create": "Passwort erstellen",
        "save": "Speichern",
        "delete": "Löschen",
        "abort": "Abbrechen"
    },


// Gender
    "gender": {
        "title": "Anrede",
        "female": "Frau",
        "male": "Mann",
        "divers": "Divers",
        "indefinite": "Unbestimmt",
        "official": {
            "title": "Anrede",
            "female": "Frau",
            "male": "Herr",
            "divers": "Divers",
            "indefinite": "Unbestimmt"
        }
    },

// Status
    "status": {
        "active": "Aktiv",
        "not_active": "Inaktiv",
    },

// Search
    "search": {
        "label": "Suche...",
        "placeholder": "Suche...",
        "select": {
            "position": "Position",
            "first_name": "Vorname",
            "last_name": "Nachname",
            "email": "Email",
            "company_name": "Firmenname",
            "identification": "Personalnummer"
        },
    },

// Table
    "table": {},

// Show
    "show": {},

// Create
    "create": {
        "title": "Neuen Kunden erstellen",
        "identification": "Die ID-Nummer wird automatisch generiert",
        "main": {
            "title": "Main"
        },
        "errors": {
            "user_hast": "Dieser Kunde wurde bereits hinzugefügt",
            "required_fields": "Alle erforderlichen Felder müssen ausgefüllt werden"
        }
    },

    // Edit
    "edit": {
        "title": "Kundendaten ändern",
    },
    "crm": {
        "title": "Kunden CRM System",
        "buttons": {
            "customers": "Kunden",
            "role_and_permissions": "Rolle und Berechtigungen"
        },
        "create": {
            "title": "Neuen Kunde erstellen",
            "can_faq": "Kunde kann FAQ erstellen",
            "multi_company": "Kunde kann Mehrere Firmen erstellen",
        },
        "edit": {
            "title": "Kundendaten ändern",
            "can_faq": "Kunde kann FAQ erstellen",
            "multi_company": "Kunde kann Mehrere Firmen erstellen",
        },
        "search": {
            "user": {
                "title": "Suche nach",
                "company_name": "Firmenname",
                "first_name": "Vorname",
                "last_name": "Nachname",
            },
            "customer_search_label": "Wählen Sie eine Kunde",
            "customer_search_info": "Suche nach Nachnamen",
        },
        "company": {
            "id": 'ID',
            "company": 'Firma',
            "companies": 'Firmen',
            "new_company": "Neue Firma",
            "companies_not_found": "Sie haben noch keine registrierte Firma",
            "edit_company": "Firma ändern",
            "edit_company_title": "Firma und die Hauptadresse ändern",
            "company_name": "Name der Firma",
            "company_name_short": "Abgekürzter Firmenname",
            "customer_number": "Kundennummer",
            "email": "Email",
            "company_phone": "Telefonnummer",
            "yes": "Ja",
            "no": "Nein",

            // GEO
            "country": 'Land',
            "city": 'Stadt',
            "postcode": 'Postleitzahl',
            "street": 'Straße und Hausnummer',
            "main_address": "Hauptadresse",

            "countries": {
              "germany": "Deutschland"
            },

            // Address
            "address_short_names": 'Name des Standorts',
            "address_short_name": 'Standort',
            "location": "Standort",
            "locations": "Standorte",
            "new_location": "Standort",
            "edit_location": "Standort ändern",
            "delete_location": "Standort löschen?",

            // Ansprechpartner
            "delete_contact_person": "Ansprechpartner löschen?",

            // Prompt
            "prompt": {
                "company": {
                    "title": "{company_name} löschen?",
                    "subtitle": "Alle Daten über das Unternehmen und die damit verbundenen Daten werden ohne Rückgabe gelöscht."
                },
                "address": {
                    "title": "Adresse löschen?",
                    "subtitle": "Alle Daten über diese adresse und die damit verbundenen Daten werden ohne Rückgabe gelöscht."
                },
                "person": {
                    "title": "Ansprechpartner löschen?",
                    "subtitle": "Alle Daten über dieser Ansprechpartner und die damit verbundenen Daten werden ohne Rückgabe gelöscht."
                },
                "cad": {
                    "title": "Zugangsdaten löschen?",
                    "subtitle": "Alle Daten über dieser Zugangsdaten und die damit verbundenen Daten werden ohne Rückgabe gelöscht."
                }
            },

            // Time
            "opening_hours": "Öffnungszeiten",
            "closed": 'Geschlossen',
            "day_of_week": {
                'mo': 'Montag',
                'di': 'Dinstag',
                'mi': 'Mittwoch',
                'do': 'Donerstag',
                'fr': 'Freitag',
                'sa': 'Samstag',
                'so': 'Sonntag',
            },
            "day_of_week_short": {
                'mo': 'Mo',
                'di': 'Di',
                'mi': 'Mi',
                'do': 'Do',
                'fr': 'Fr',
                'sa': 'Sa',
                'so': 'So',
            },

            // Person
            "person": {
                "title": "Ansprechpartner",
                "new_person": "Ansprechpartner",
                "delete_all_contact_person": "Vorher alle Ansprechpartner löschen ",
                "dropdown": {
                    "title": "Ansprechpartner",
                    "new_person": "Neue Ansprechpartner",
                },
                "csv": {
                    "load": "Hochladen",
                    "label": "CSV File",
                    "button_export": "CSV File Exportieren",
                    "button_import": "CSV File Importieren",
                    "errors": {
                        "500": "Die Datei ist beschädigt oder stimmt nicht mit den Parametern überein. Bitte wählen Sie eine andere Datei."
                    },
                    "prompt": {
                        "title": "Möchten Sie neue Ansprechpartner hochladen?",
                        "subtitle": "Мaximal 150 Ansprechpartner in einer Datei!",
                        "complete_title": "Alle Ansprechpartner wurden hinzugefügt!",
                        "max_length_or_d": "Sie haben den Maximalwert überschritten oder die Datei ist beschädigt!",
                        "warning": "Beim Hochladen neuer Kontakte werden alle Kontakte in der Datenbank gelöscht!",
                        "warning_btn": "Einverstanden",
                        "file_not_exist": "Sie müssen die Datei auswählen!",
                        "file_extension": "Erlaubte Dateiformate (.csv)",
                    },
                },
                "table": {
                    "not_found": "Noch keinen registrierten Ansprechpartner",
                    "id": "ID",
                    "gender": "Anrede",
                    "position": "Position",
                    "first_name": "Vorname",
                    "last_name": "Nachname",
                    "email": "Email",
                    "phone": "Telefonnummer",
                    "identification": "Personalnummer"
                },
                "form": {
                    "position": "Position",
                    "first_name": "Vorname",
                    "last_name": "Nachname",
                    "phone": "Telefonnummer",
                    "birthday": "Geburtstag",
                    "email": "Email",
                    "gender": "Anrede",
                    "put_through": "Durchstellen (Telefongespräch weiterleiten)",
                    "gender_select": "Bitte wählen Sie ein Geschlecht"
                }
            },

            // Customer access Data
            "cad": {
                "subtitle": "Zugangsdaten",
                "delete_title": "Zugangsdaten Löschen?",
                "title": "Title",
                "description": "Beschreibung",
                "url": "URL",
                "login": "Login",
                "password": "Passwort",
                "is_active": {
                    "title": "Ist aktiv",
                    "yes": "Ja",
                    "no": "Nein",
                },
                "btn": {
                    "new": "Neu zugangsdaten",
                    "save": "Speichern",
                    "delete": "Löschen",
                    "edit": "Ändern",
                },
                "table": {
                    "not_found": "",
                }
            },
            "faq": {
                "title": "FAQ",
                "search": "Suchen",
                "welcome_text":"Begrüßungstext",
                "save": "Speichern",
                "abort": "Abbrechen",
                "edit": "FAQ ändern",
                "question": {
                    "title": "Fragen",
                    "button": "Neue Frage",
                    "button_csv_export": "Fragen CSV Exportieren",
                    "create": "Neue Frage",
                    "update": "Frage ändern",
                    "get": "Fragen abrufen", // Antworten abrufen
                    "csv": {
                        "load": "Fragen hochladen",
                        "button_export": "Fragen CSV Exportieren",
                        "button_import": "Fragen CSV hochladen ",
                        "prompt": {
                            "title": "Möchten Sie neue Fragen hochladen? ",
                            "subtitle": "Beim Hochladen neuer Fragen werden alle alten Fragen in der Datenbank gelöscht!",
                        },
                    },
                    "prompt": {
                        "title": "Frage löschen?",
                        "subtitle": "Diese frage werden ohne Rückgabe gelöscht.",
                    },
                    "field": {
                        "title": "Frage",
                        "text": "Antwort",
                        "notice": "Notize",
                    }
                }
            },
            "conversation": {
                "title_menu": "Historie", //Conversation
                "title": "Historie", //Conversation
                "employee": "Mitarbeiter",
                "contact_person": "Ansprechpartner",
                "tags": {
                    "show": "Tags",
                    "title": "Tags",
                    "display_text": "Display text",
                    "code_text": "Code text",
                    "color": "Farbe",
                    "create": "Neue Tag",
                    "reload": "Aktualisieren ",
                },
                "form": {
                    "employee": "Mitarbeiter",
                    "description": "Beschreibung",
                    "contact_person": "Ansprechpartner",
                    "tags": "Tags",
                    "tags_placeholder": "Tag auswählen",
                }
            },
        }
    }
}


