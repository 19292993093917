export default {
  version: 'Version: v1.4.1-alpha',                       // Версия продукта
  multi_company: false,                                 // Создовать множество компаний
  watcher_time: 60,                                     // Указать в минутах через какое время должене разлогинется пользователь!
  admin_message_time: 60000,                            // 60000 = 1 min запрос к серверу н проверку сообщений!
  watcher_debug: false,                                 // Вывод в консоли!
  preload_time: 100,                                    // 300 время задержки preloader setTimeout()
  preload_logo: true,                                   // показывать логотип при лоадаре.
  home_preload_time: 1200,                              // время задержки preloader setTimeout() на странице HOME
  language: 'de',                                       // Default Language
  route_mode: 'history',                                // history, hash = для cordova
  default_url: (process.env.NODE_ENV === 'production') ? 'https://manager-api.peepz.net' : 'http://peepz.admin.api.loc',
  avatar_url: (process.env.NODE_ENV === 'production') ? 'https://employees.peepz.net/storage/' : 'http://peepz.employees.loc/storage/',
  document_url: (process.env.NODE_ENV === 'production') ? 'https://documents.peepz.net/storage/' : 'http://peepz.crm.document.loc/storage/',
  document_url_api: (process.env.NODE_ENV === 'production') ? 'https://documents.peepz.net/api' : 'http://peepz.crm.document.loc/api',
}
