import * as Service from '@/services/customer/customer_gateway_service'

export default {
    namespace: true,
    state: {
        customer_gateway: {},
        customer_gateways: [],
        customer_crm_edit_disabled: false,
    },
    getters: {
        customersCrm(state) {
            return state.customer_gateways
        },
        customerCrm(state) {
            return state.customer_gateway
        },
        customerCrmEditDisabled(state) {
            return state.customer_crm_edit_disabled
        }
    },
    mutations: {
        SET_CUSTOMERS_CRM(state, customer_gateways) {
            state.customer_gateways = customer_gateways
        },
        SET_CUSTOMER_CRM(state, customer_gateway) {
            state.customer_gateway = customer_gateway
        },
        SET_CUSTOMER_CRM_EDIT_DISABLED(state, bool) {
            state.customer_crm_edit_disabled = bool
        }
    },
    actions: {
        /* Customers */
        async customersCrm({commit, state}, page) {
            // if (state.customers.length === 0) {
            await Service.customersCrm(page).then(response => {
                commit('SET_CUSTOMERS_CRM', response.data)
            })
            // }
        },

        /* Customers */
        async customerCrmSearch({commit, state}, data) {
            await Service.customerCrmSearch(data).then(response => {
                commit('SET_CUSTOMERS_CRM', response.data)
            })
        },

        async customerCrmCreate({commit, state}, data) {
            await Service.customerCrmCreate(data)
        },

        async customerCrmEdit({commit, state}, id) {
            await Service.customerCrmEdit(id).then(response => {
                commit('SET_CUSTOMER_CRM', response.data)
            })
        },

        async customerCrmUpdate({commit, state}, data) {
            await Service.customerCrmUpdate(data)
        },

        async customerCrmShow({commit, state}, id) {
            await Service.customerCrmShow(id).then(response => {
                commit('SET_CUSTOMER', response.data)
            })
        },

        async customerCrmDelete({commit, state}, id) {
            await Service.customerCrmDelete(id)
        }
    }
}