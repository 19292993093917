import * as Service from '@/services/customer/customer_contact_person_service'

export default {
    namespace: true,
    state: {
        customer_contact_person: {},
        customer_gateways: [],
    },
    getters: {
        customerContactPerson(state) {
            return state.customer_contact_person
        },

    },
    mutations: {
        SET_CUSTOMER_CONTACT_PERSON(state, customer_contact_person) {
            state.customer_contact_person = customer_contact_person
        },
    },
    actions: {
        async contactPersonCreate({commit, state}, data) {
            await Service.contactPersonCreate(data).then(response => {
                commit('SET_CUSTOMER_CONTACT_PERSON', response.data.data)
            })
        },

        async contactPersonUpdate({commit, state}, data) {
            await Service.contactPersonUpdate(data).then(response => {
                commit('SET_CUSTOMER_CONTACT_PERSON', response.data)
            })
        },

        async contactPersonDelete({commit, state}, id) {
            await Service.contactPersonDelete(id).then(response => {
                commit('SET_CUSTOMER_CONTACT_PERSON', response.data)
            })
        },

    }
}
