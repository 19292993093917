/* Middleware */
import auth from '@/middleware/auth';
import {show, create, update} from "@/middleware/permissions/manager_crm";


export default [
    {
        path: 'users',
        name: 'users',
        meta: {
            layout: 'main',
            middleware: [auth, show]
        },
        component: () => import('@/views/user/UsersView'),
    },
    {
        path: 'users/create',
        name: 'users.create',
        meta: {
            layout: 'main',
            middleware: [auth, create]
        },
        component: () => import('@/views/user/UserCreateView')
    },
    {
        path: 'users/:id/edit',
        name: 'users.edit',
        meta: {
            layout: 'main',
            middleware: [auth, update]
        },
        component: () => import('@/views/user/UserEditView')
    },

];
