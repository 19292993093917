import { http } from "../http_service";


/** CUSTOMERS */
export function customers(page) {
    return http().get(`/customers?page=${page}`);
}

/** CUSTOMER SHOW */
export function customerShow(id) {
    return http().get(`/customers/${id}/show`);
}

/** CUSTOMER EDIT */
export function customerEdit(id) {
    return http().get(`/customers/${id}/edit`);
}

/** CUSTOMER CREATE */
export function customerCreate(data) {
    return http().post(`/customers`, data);
}

/** CUSTOMER UPDATE */
export function customerUpdate(data) {
    return http().put(`/customers/${data.id}`, data);
}

/** CUSTOMER SEARCH */
export function customerSearch(data) {
    return http().post(`/customers/search`, data);
}

/** CUSTOMER DELETE */
export function customerDelete(id) {
    return http().delete(`/customers/${id}`);
}