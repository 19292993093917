/* Middleware */
import auth from '@/middleware/auth';

import employee from './_children/_c_employee_crm_router'
import report from './_children/_c_report_router'
import laratrust from './_children/_c_laratrust_router'

import {show} from "@/middleware/permissions/employee_crm";

export default [
    {
        path: '/crm-employee',
        name: 'crm_employee',
        meta: {
            layout: 'main',
            middleware: [auth, show]
        },
        redirect: '/crm-employee/employees',
        component: () => import('@/views/employee/crm/CrmView'),

        children: [
            ...employee,
            ...report,
            ...laratrust
        ]
    },

];
