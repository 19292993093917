/* Middleware */
import auth from '@/middleware/auth';
import {show, create, update} from '@/middleware/permissions/customer_crm'

export default [
    {
        path: 'customers',
        name: 'crm.customers',
        meta: {
            layout: 'main',
            middleware: [auth, show]
        },
        component: () => import('@/views/customer/crm/CustomersCRMView'),
    },
    {
        path: 'customers/create',
        name: 'crm.customers.create',
        meta: {
            layout: 'main',
            middleware: [auth, create]
        },
        component: () => import('@/views/customer/crm/CustomerCreateCRMView')
    },
    {
        path: 'customers/:id/show',
        name: 'crm.customers.show',
        meta: {
            layout: 'main',
            middleware: [auth, show]
        },
        component: () => import('@/views/customer/crm/CustomerShowCRMView')
    },
    {
        path: 'customers/:id/edit',
        name: 'crm.customers.edit',
        meta: {
            layout: 'main',
            middleware: [auth, update]
        },
        component: () => import('@/views/customer/crm/CustomerEditCRMView')
    },
];
