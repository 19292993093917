<template>
<div class="d-flex align-center" v-if="!preloader">
  <h2 class="mx-time d-flex align-center" style="font-weight: 500; font-size: 30px;">
    <span class="mx-time-clock" style="text-align: center">{{ hour }}</span>
    <span class="mx-time-separator">:</span>
    <span class="mx-time-clock">{{ min }}</span>
<!--    <span class="mx-time-separator">:</span>-->
<!--    <span class="mx-time-clock">{{ sec }}</span>-->
  </h2>
  <div class="d-flex flex-column mt-1 ml-3 mx-time-date">
    <span class="mb-n1">{{ date }}</span>
    <span style="color: #14b1dc; font-weight: 700; letter-spacing: 0.03rem">{{ $t(`week_day.${day}`) }}</span>
  </div>
</div>
</template>

<script>
import moment from "moment"

export default {
  name: "Date",
  data() {
    return {
      preloader: true,
      hour: 13,
      min: 15,
      sec: 15,
      day: '',
      date: '',
      interval: null,
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  created() {
    this.interval = setInterval(() => {
      this.hour = moment().format('HH')
      this.min = moment().format('mm')
      this.sec = moment().format('ss')
      this.date = moment().format('DD.MM.YYYY')
      this.day = moment().format('dddd')
    }, 1000)

    setTimeout(()=> {this.preloader = false}, 1000)
  }
}
</script>

<style lang="scss">
.mx-time {
  font-family: 'Exo 2', sans-serif;

  &-clock {
    width: 36px;
    font-weight: 700;
  }
  &-separator {
    margin: -5px 2px 0 0;
  }
  &-date {
    font-size: 12px;
  }
}
</style>
