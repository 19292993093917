
import auth from '@/middleware/auth';
import {showPermission, showRole, createRole, updateRole} from "@/middleware/permissions/laratrust";

export default [
    {
        path: 'laratrust/permissions',
        name: 'permissions.employees',
        meta: {
            layout: 'main',
            middleware: [auth, showPermission]
        },
        component: () => import('@/views/employee/crm/laratrust/permission/PermissionsView')
    },
    {
        path: 'laratrust/roles',
        name: 'roles.employees',
        meta: {
            layout: 'main',
            middleware: [auth, showRole]
        },
        component: () => import('@/views/employee/crm/laratrust/role/RolesView')
    },
    {
        path: 'laratrust/roles/create',
        name: 'roles.employees.create',
        meta: {
            layout: 'main',
            middleware: [auth, createRole]
        },
        component: () => import('@/views/employee/crm/laratrust/role/RoleCreateView')
    },
    {
        path: 'laratrust/roles/:id',
        name: 'roles.employees.edit',
        meta: {
            layout: 'main',
            middleware: [auth, updateRole]
        },
        component: () => import('@/views/employee/crm/laratrust/role/RoleEditView')
    }
];