import user from './user/_lang_user'
import auth from './_lang_auth'
import error from './_lang_errors'
import company from './_lang_company'
import faq from './_lang_faq'
import middleware from './_lang_middelware'
import customer from './_lang_customer'
import employee from './_lang_employee'
import report from './_lang_report'
import document from './_lang_document'
import notice from '@/modules/notice/lang/de/lang'

export default {
    "greeting": "Hallo",
    "not_found": "Nicht gefunden",
    "page_not_found": "Site nicht gefunden",
    "to_home": "Zurück",
    "user": user,
    "auth": auth,
    "error": error,
    "company": company,
    "customer": customer,
    "employee": employee,
    "report": report,
    "faq": faq,
    "document": document,
    "middleware": middleware,
    "notice": notice,
    "copyright": '© peepz GmbH {year}, All Rights Reserved',
    "bool":{
        "yes": "Ja",
        "no": "Nein"
    },
    "language": {
        "de": {
            "de": "Deutsch",
            "en": "English",
            "ru": "Russisch"
        },
        "en": {
            "en": "English",
            "de": "Germany",
            "ru": "Russia"
        },
        "ru": {
            "ru": "Русский",
            "de": "Немецкий",
            "en": "Английский"
        }
    },
    "countries":{
        "germany": "Deutschland",
    },
    "setting": {
        "settings": {
            "title": "Einstellungen",
            "tab_title": "Einstellungen",
            "changed": "Einstellungen wurde geändert",
            "user": {
                "identification": "Personalnummer",
                "email": "Email"
            },
            "form": {
                "first_name": 'Vorname',
                "last_name": 'Nachname',
                "language": 'Sprache',
            },
            "errors": {
                "first_name": 'Vorname kann nicht leer sein',
                "last_name": 'Nachname kann nicht leer sein',
            }
        },
        "password": {
            "title" : "Passwort ändern",
            "tab_title" : "Passwort ändern",
            "form": {
                "label": 'Passwort',
                "label_confirmation": "Passwort Bestätigung",
                "password_do_not_match": "Passwörter stimmen nicht überein!",
                "password_changed": "Das Passwort wurde geändert"
            },
            "generate": {
                "title": "Passwort",
                "auto": "Manuell  generieren",
                "manuel": "Automatisch generieren ",
            }
        }
    },
    "password": {
        "generate": {
            "title": "Passwort",
            "auto": "Automatisch generieren",
            "manuel": "Manuell generieren ",
        }
    },
    "dashboard": {
        "title": "Dashboard",
        "employees_online": "Mitarbeiter online"
    },
    "week_day": {
        "Monday": "Montag",
        "Tuesday": "Dienstag",
        "Wednesday": "Mittwoch",
        "Thursday": "Donnerstag",
        "Friday": "Freitag",
        "Saturday": "Samstag",
        "Sunday": "Sonntag",
    },
    "conversation": {
        "title_last": "Neue Historie", //Conversation
        // Table
        "table": {
            "company": "Firmaname",
            "employee": "Mitarbeiter",
            "description": "Beschreibung",
            "contact_person": "Ansprechpartner",
            "tag": "Tag",
            "tage": "Keine Notizen verfügbar",
            "date": "Date",
        },
    }
}
