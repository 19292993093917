import auth from '@/middleware/auth';
import {show, create, update} from "@/middleware/permissions/employee";

export default [
    {
        path: '/users/employees',
        name: 'employees',
        meta: {
            layout: 'main',
            middleware: [auth, show]
        },
        component: () => import('@/views/employee/EmployeesView')
    },
    {
        path: '/users/employees/create',
        name: 'employees.create',
        meta: {
            layout: 'main',
            middleware: [auth, create]
        },
        component: () => import('@/views/employee/EmployeeCreateView')
    },
    {
        path: '/users/employees/:id/edit',
        name: 'employees.edit',
        meta: {
            layout: 'main',
            middleware: [auth, update]
        },
        component: () => import('@/views/employee/EmployeeEditView')
    }
];