/* Middleware */
import auth from '@/middleware/auth';

import documents from './_children/_c_document_router'
import Categories from './_children/_c_category_router'


export default [
    {
        path: '/documents',
        name: 'documents',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        redirect: '/documents/all',
        component: () => import('@/views/document/DocumentView'),
        children: [
            ...documents,
            ...Categories
        ]
    },
];
