import { http } from "../http_service";


/** Users */
export function users(page) {
    return http().get(`/users?page=${page}`);
}

/** User SHOW */
export function userShow(id) {
    return http().get(`/users/${id}`);
}

/** User SEARCH */
export function userSearch(data) {
    return http().post(`/users/search`, data);
}

/** User EDIT */
export function userEdit(id) {
    return http().get(`/users/${id}/edit`);
}

/** User CREATE Get*/
export function userCreateGet() {
    return http().get(`/users/create`);
}

/** User CREATE */
export function userCreate(data) {
    return http().post(`/users`, data);
}

/** User UPDATE */
export function userUpdate(data) {
    return http().put(`/users/${data.id}`, data);
}

/** User DELETE */
export function userDelete(id) {
    return http().delete(`/users/${id}`);
}