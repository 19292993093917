import { http } from "../http_service";


/** CUSTOMERS */
export function customersCrm(page) {
    return http().get(`/customers-gateway?page=${page}`);
}

/** CUSTOMER SHOW */
export function customerCrmShow(id) {
    return http().get(`/customers-gateway/${id}/show`);
}

/** CUSTOMER EDIT */
export function customerCrmEdit(id) {
    return http().get(`/customers-gateway/${id}/edit`);
}

/** CUSTOMER CREATE */
export function customerCrmCreate(data) {
    return http().post(`/customers-gateway`, data);
}

/** CUSTOMER UPDATE */
export function customerCrmUpdate(data) {
    return http().put(`/customers-gateway/${data.id}`, data);
}

/** CUSTOMER SEARCH */
export function customerCrmSearch(data) {
    return http().post(`/customers-gateway/search`, data);
}

/** CUSTOMER DELETE */
export function customerCrmDelete(id) {
    return http().delete(`/customers-gateway/${id}`);
}