/* Middleware */
import auth from '@/middleware/auth';

import user from './_children/_c_user_crm_router'
import laratrust from "./_children/_c_laratrust_router"
import {show} from "@/middleware/permissions/manager_crm";

export default [
    {
        path: '/crm-user',
        name: 'crm_user',
        meta: {
            layout: 'main',
            middleware: [auth, show]
        },
        redirect: '/crm-user/users',
        component: () => import('@/views/user/crm/CrmView'),

        children: [
            ...user,
            ...laratrust
        ]
    },

];
