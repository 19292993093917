import * as Service from '@/services/employee/employee_service'

export default {
    namespace: true,
    state: {
        employee: {},
        employees: [],
    },
    getters: {
        employees(state) {
            return state.employees
        },
        employee(state) {
            return state.employee
        }
    },
    mutations: {
        SET_EMPLOYEES(state, employees) {
            state.employees = employees
        },
        SET_EMPLOYEE(state, employee) {
            state.employee = employee
        }
    },
    actions: {
        async employees({commit, state}, page) {
            // if (state.customers.length === 0) {
            await Service.employees(page).then(response => {
                commit('SET_EMPLOYEES', response.data)
            })
            // }
        },

        async employeeSearch({commit, state}, data) {
            await Service.employeeSearch(data).then(response => {
                commit('SET_EMPLOYEES', response.data)
            })
        },

        async employeeCreate({commit, state}, data) {
            await Service.employeeCreate(data)
        },

        async employeeEdit({commit, state}, id) {
            await Service.employeeEdit(id).then(response => {
                commit('SET_EMPLOYEE', response.data)
            })
        },

        async employeeUpdate({commit, state}, data) {
            await Service.employeeUpdate(data)
        },

        async employeeShow({commit, state}, id) {
            await Service.employeeShow(id).then(response => {
                commit('SET_EMPLOYEE', response.data)
            })
        },

        async employeeDelete({commit, state}, id) {
            await Service.employeeDelete(id)
        },

        async employeeAvatarDelete({commit, state}, data) {
            await Service.employeeAvatarDelete(data)
        }
    }
}