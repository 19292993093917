import { http } from "../http_service";


/** COMPANIES **/
export function companiesFromCustomer(customer_id) {
    return http().get(`/customer/companies/${customer_id}`);
}

export function companyCreate(data) {
    return http().post(`/customer/companies`, data);
}

export function companyUpdate(data) {
    return http().put(`/customer/companies/${data.id}`, data);
}

export function companyDelete(company_id) {
    return http().delete(`/customer/companies/${company_id}`);
}


/** ADDRESS **/
export function companyAddressCreate(data) {
    return http().post(`/customer/companies/addresses/${data.company_id}`, data);
}

export function companyAddressUpdate(data) {
    return http().put(`/customer/companies/addresses/${data.id}`, data);
}

export function companyAddressDelete(address_id) {
    return http().delete(`/customer/companies/addresses/${address_id}`);
}


/** CONTACT PERSON **/
export function companyContactPersonCreate(data) {
    return http().post(`/customer/companies/contact-persons`, data);
}

export function companyContactPersonUpdate(data) {
    return http().put(`/customer/companies/contact-persons/${data.id}`, data);
}

export function companyContactPersonDelete(id) {
    return http().delete(`/customer/companies/contact-persons/${id}`);
}


/** CUSTOMER ACCESS  DATA **/
export function companyCustomerAccessDataCreate(data) {
    return http().post(`/customer/companies/customer-access-data`, data);
}
export function companyCustomerAccessDataUpdate(data) {
    return http().put(`/customer/companies/customer-access-data/${data.id}`, data);
}
export function companyCustomerAccessDataDelete(id) {
    return http().delete(`/customer/companies/customer-access-data/${id}`);
}

/** FAQ **/
export function companyFAQ(company_id) {
    return http().get(`/customer/companies/faq/${company_id}`);
}
export function companyFAQUpdate(data) {
    return http().put(`/customer/companies/faq/${data.id}`, data);
}

/** FAQ Question **/
export function companyFaqQuestionCreate(data) {
    return http().post(`/customer/companies/faq/questions`, data);
}
export function companyFaqQuestionUpdate(data) {
    return http().put(`/customer/companies/faq/questions/${data.id}`, data);
}
export function companyFaqQuestionDelete(id) {
    return http().delete(`/customer/companies/faq/questions/${id}`);
}
