import * as Service from '@/services/customer/customer_service'

export default {
    namespace: true,
    state: {
        customer: {},
        customers: [],
    },
    getters: {
        customers(state) {
            return state.customers
        },
        customer(state) {
            return state.customer
        }
    },
    mutations: {
        SET_CUSTOMERS(state, customers) {
            state.customers = customers
        },
        SET_CUSTOMER(state, customer) {
            state.customer = customer
        }
    },
    actions: {
        /* Customers */
        async customers({commit, state}, page) {
            // if (state.customers.length === 0) {
                await Service.customers(page).then(response => {
                    commit('SET_CUSTOMERS', response.data)
                })
            // }
        },

        /* Customers */
        async customerSearch({commit, state}, data) {
            await Service.customerSearch(data).then(response => {
                commit('SET_CUSTOMERS', response.data)
            })
        },

        async customerCreate({commit, state}, data) {
            await Service.customerCreate(data)
        },

        async customerEdit({commit, state}, id) {
            await Service.customerEdit(id).then(response => {
                commit('SET_CUSTOMER', response.data)
            })
        },

        async customerUpdate({commit, state}, data) {
            await Service.customerUpdate(data)
        },

        async customerShow({commit, state}, id) {
            await Service.customerShow(id).then(response => {
                commit('SET_CUSTOMER', response.data)
            })
        },

        async customerDelete({commit, state}, id) {
            await Service.customerDelete(id)
        }
    }
}