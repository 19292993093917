import store from '@/store'

export default {
    hasPermission(permission) {
        const permissions = store.getters.auth_permissions
        return permissions.find((per) => per === permission) !== undefined
    },

    can(permission) {
        const permissions = store.getters.auth_permissions
        return permissions.find((per) => per === permission) !== undefined
    },

    subPermission(permissions) {
        const auth_permissions = store.getters.auth_permissions
        for (let i = 0; i < permissions.length; i++) {
            for (let j = 0; j < auth_permissions.length; j++) {
                if (auth_permissions[j] === permissions[i]) {
                    return true
                }
            }
        }
        return false
    },

    hasRole(role) {
        const roles = store.getters.auth_roles
        return roles.find((rol) => rol === role) !== undefined
    }
}
