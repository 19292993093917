import { http } from "../http_service";


export function contactPersonCreate(data) {
    return http().post(`/cg-contact-persons`, data);
}

export function contactPersonUpdate(data) {
    return http().put(`/cg-contact-persons/${data.id}`, data);
}

export function contactPersonDelete(id) {
    return http().delete(`/cg-contact-persons/${id}`);
}