import axios from 'axios';
import options from "@/options";

export function http() {
    return axios.create({});
}

export function httpFile() {
    return axios.create({
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
}

export function httpDocumentFile() {
    return axios.create({
        baseURL: options.document_url_api,
        headers: {
            'Content-Type': 'application/json',
            'Content-Xl': '789'
        }
    });
}