<template>
  <v-app-bar app dense elevation="1" color="header_bg" dark>
<!--    <v-app-bar-nav-icon class="d-lg-none" @click.stop="drawerLeft()"></v-app-bar-nav-icon>-->
<!--    <v-app-bar-nav-icon class="d-xl-none" @click.stop="drawerLeft()"></v-app-bar-nav-icon>-->
    <v-app-bar-nav-icon @click.stop="drawerLeft()" class="mr-2"></v-app-bar-nav-icon>
<!--    <router-link to="/">-->
<!--      <v-img-->
<!--          class="mr-6"-->
<!--          max-width="96"-->
<!--          src="/assets/images/logo.png"-->
<!--          alt="Peepz GmbH"-->
<!--      ></v-img>-->
<!--    </router-link>-->
<!--    <v-btn-->
<!--        :ripple="false"-->
<!--        hidden-->
<!--        plain-->
<!--        class="d-md-flex"-->
<!--        active-class="mx-btn-active"-->
<!--        v-for="link in links"-->
<!--        :key="link.title"-->
<!--        :to="link.url"-->
<!--    >-->
<!--      {{ link.title }}-->
<!--    </v-btn>-->
<!--    <v-spacer></v-spacer>-->
<!--    <v-chip color="primary">-->
<!--      {{ $t('employee.employees_online') }}-->
<!--      <v-chip color="" small class="ml-1 mr-n2">-->
<!--        {{ $store.getters.employeesCrmDashboard.length }}-->
<!--      </v-chip>-->
<!--    </v-chip>-->
    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
    <Date class="mt-n1 mr-8" />
    <div class="d-flex align-center" style="background: #3d3d3d; height: 48px;">
     <TopUserMenu/>
   </div>
  </v-app-bar>
</template>

<script>
import TopUserMenu from "@/components/User/TopUserMenu";
import Date from "@/components/Header/MxDate/MxFullDate";

export default {
  components: {TopUserMenu, Date},
  data() {
    return {
      links: []
    }
  },
  created() {
    this.links = this.$store.getters.menu
  },
  methods: {
    drawerLeft() {
      const bool = this.$store.getters.drawer_left;
      this.$store.commit('set_drawer_left', !bool)
    }
  }
}
</script>
