export default {
  "first_name": "Vorname",
  "last_name": "Nachname",
  "password": "Passwort",
  "email": "Email",
  "identification": "Personalnummer",
  "birthday": "Geburtstag",
  "email_send": "Email an den Benutzer senden",
  "is_blocked": "Benutzer Blockiert",
  "is_admin": "Superadmininistrator",
  "position": "Position",
  "role": {
    "title": "Role",
    "roles": {}
  },
  "cover": {
    "success": "Ok",
    "abort": "Abrechnen",
    "select_avatar": "Photo suchen",
    "other_avatar": "Andere photo",
  },
  "permission": {
    "title": "Permissions",
    "permissions": {
      "user-create": "Benutzer erstellen ",
      "user-read": "Benutzer sehen",
      "user-update": "Benutzer ändern ",
      "user-delete": "Benutzer löschen",
      "role-create": "Rolle erstellen",
      "role-read": "Rolle sehen",
      "role-update": "Rolle ändern",
      "role-delete": "Rolle löschen",
      "permission-create": "Genehmigung erstellen",
      "permission-read": "Genehmigung sehen",
      "permission-update": "Genehmigung ändern",
      "permission-delete": "Genehmigung löschen",
    }
  },
  "status": {
    "active": "Aktiv",
    "not_active": "Inaktiv",
  },
  "employment": {
    "intern": "Intern",
    "extern": "Extern",
    "client": "Kunde",
  },
  "gender": {
    "title": "Geschlecht",
    "female": "Weiblich",
    "male": "Männlich",
    "divers": "Divers",
    "indefinite": "Unbestimmt"
  },
  "buttons": {
    "avatar_create": "Avatar erstellen",
    "password_create": "Passwort erstellen",
    "save": "Speichern",
    "delete": "Löschen",
    "abort": "Abbrechen"
  },
  "titles": {
    "personal_data": "Persönlichen daten",
    "role_and_permissions": "Rolle und Berechtigungen",
    "settings": "Einstellungen",
    "password": "Passwort",
    "avatar": "Avatar",
    "employee_delete": "Wollen Sie diese Mitarbeiter löschen?",
    "user_delete": "Wollen Sie dieser Benutzer löschen?",
  },
  "search": {
    "label": "Suche...",
    "placeholder": "Suche...",
    "select": {
      "position": "Position",
      "first_name": "Vorname",
      "last_name": "Nachname",
      "email": "Email",
      "identification": "Personalnummer"
    },
  },
  "create": {
    "title": "Neuen Benutzer erstellen",
    "employee_search_label": "Wählen Sie ein Mitarbeiter",
    "employee_search_info": "Suche nach Nachnamen",
    "identification": "Die ID-Nummer wird automatisch generiert",
    "main": {
      "title": "Main"
    },
    "errors": {
      "user_hast": "Dieser Benutzer wurde bereits hinzugefügt",
      "required_fields": "Alle erforderlichen Felder müssen ausgefüllt werden"
    }
  },
  "edit": {
    "title": "Benutzerdaten ändern",
  },
  "crm": {
    "title": "Manager CRM",
    "buttons": {
      "users": "Managers",
      "role": "Rolle",
      "permissions": "Berechtigungen",
    }
  }
}
