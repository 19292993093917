/* Middleware */
import auth from '@/middleware/auth';
import {show, create, update} from "@/middleware/permissions/employee_crm";

export default [
    {
        path: 'employees',
        name: 'crm.employees',
        meta: {
            layout: 'main',
            middleware: [auth, show]
        },
        component: () => import('@/views/employee/crm/employee/EmployeesCRMView'),
    },
    {
        path: 'employees/create',
        name: 'crm.employees.create',
        meta: {
            layout: 'main',
            middleware: [auth, create]
        },
        component: () => import('@/views/employee/crm/employee/EmployeeCreateCRMView')
    },
    {
        path: 'employees/:id/edit',
        name: 'crm.employees.edit',
        meta: {
            layout: 'main',
            middleware: [auth, update]
        },
        component: () => import('@/views/employee/crm/employee/EmployeeEditCRMView')
    },

];
