<template>
  <div class="main-layout">
<!--    <DrawerLeft class="d-md-none"/>-->
    <DrawerLeft/>
    <Header/>
    <v-main>
      <router-view/>
    </v-main>
  </div>
</template>

<script>

import Header from "@/components/Header";
import DrawerLeft from "@/components/DrawerLeft";
import {activityWatcher} from "@/scripts/watcher";

export default {
  components: {Header, DrawerLeft},
  data() {
    return {}
  },
  mounted() {
    //this.watcher = activityWatcher(this.options.watcher_time);
    //setTimeout(this.preload, this.options.preload_time);
  },
  destroyed() {
    //clearTimeout(this.watcher)
  },
  methods: {
    darkTheme(bool) {
      this.$vuetify.theme.dark = bool
    },
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    }
  },
}
</script>