export default {
    "title": 'Dokumente',
    "name": 'Dokument name',
    "placeholder_file": 'Wählen Sie Ihre Dateien aus',
    "create": {
        "title": "Neu Dokument"
    },
    "edit": {
        "title": "Dokument ändern"
    },
    "titles": {
        "document_delete": "Dokument löschen?"
    },
    "for": {
        "select": "Für",
        "all": "Mitarbeiter + Kunde",
        "employee": "Mitarbeiter",
        "customer": "Kunde",
    },
    "for_all": {
        "select": "Alle {element} können diese Datei sehen?",
        "yes": "Ja",
        "no": "Nein",
    },
    "menu": {
        "customer_documents": "Kundendokumente "
    },
    "top_menu": {
        "documents": "Dokumente",
        "categories": "Kategorien"
    },
    "buttons": {
        "document_create": "Dokument erstellen",
        "save": "Speichern",
        "delete": "Löschen",
        "abort": "Abbrechen"
    },
    "switch": {
      "no": "Nein",
      "yes": "Ja"
    },
    "table": {
        "id": "ID",
        "name": "Name",
        "category": "Kategorie",
        "for": "Für",
        "type": "Typ",
        "create_at": "Erstellt",
        "update_at": "Aktualisiert",
        "search": "Suchen...",
    },
    "employee": {
        "title": "Mitarbeiter",
        // "select": "Wählen Sie einen Mitarbeiter aus ",
        "select": "Mitarbeiter auswählen",
    },
    "customer": {
        "title": "Kunde",
        "select": "Kunden auswählen ",
    },
    "role": {
        "title": "Role",
        "select": "Role auswählen ",
    },
    "category": {
        "select": "Kategorie",
        "create_title": "Neu Kategorie",
        "update_title": "Kategorie ändern",
        "name": "Kategorie name",
        "employee": "Für Mitarbeiter",
        "customer": "Für Kunde",
        "can_delete": "Kann Löschen",
        "combobox": {
            "no": "Nein",
            "yes": "Ja",
        },
        "buttons": {
            "save": "Speichern",
            "abort": "Abrechnen",
        },
        "table": {
            "id": "ID",
            "name": "Kategorie name",
            "employee": "Für Mitarbeiter",
            "customer": "Für Kunde",
            "can_delete": "Kann Löschen",
        },
        "dialog": {
            "delete": {
                "title": "Kategorie löschen?",
                "buttons": {
                    "delete": "Löschen",
                    "abort": "Abrechnen"
                }
            }
        }
    }
}
