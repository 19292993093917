export default {
    "buttons": {
        "save": "Speichern",
        "delete": "Löschen",
        "abort": "Abbrechen"
    },
    "role": {
        "title": "Rollen",
        "role": "Rolle",
        "form": {
            "create": "Rolle erstellen",
            "update": "Rolle ändern"
        },
        "delete": {
            "title": "Wollen Sie diese Rolle löschen?",
        }
    },
    "permission": {
        "title": "Berechtigungen ",
        "form": {
            "permission_create": "Berechtigung erstellen",
            "permission_update": "Berechtigung ändern"
        }
    }
}
