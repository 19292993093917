export default {
    state: {
        drawer_left: true,
        menu: [
            {
                icon: 'mdi-view-dashboard',
                title: 'Dashboard',
                url: '/dashboard',
            },
            {
                icon: 'mdi-folder-outline',
                title: 'Dokumente',
                url: '/documents',
            },
            {
                icon: 'mdi-account-circle',
                title: 'Users',
                url: '/users',
                subLinks: [
                    // {
                    //     title: 'Benutzer',
                    //     // icon: 'mdi-account',
                    //     url: '/users/user',
                    // },
                    {
                        title: 'Mitarbeiter',
                        // icon: 'mdi-account-tie',
                        url: '/users/employees',
                    },
                    {
                        title: 'Kunde',
                        // icon: 'mdi-account-group',
                        url: '/users/customers',
                    }
                ]
            },
            {
                icon: 'mdi-desktop-classic',
                title: 'CRM',
                url: '/crm',
                subLinks: [
                    {
                        title: 'Mitarbeiter CRM',
                        // icon: 'mdi-desktop-mac-dashboard',
                        url: '/crm-employee',
                    },
                    {
                        title: 'Kunde CRM',
                        // icon: 'mdi-desktop-mac-dashboard',
                        url: '/crm-customer',
                    },
                    {
                        title: 'Manager CRM',
                        // icon: 'mdi-desktop-mac-dashboard',
                        url: '/crm-user',
                    }
                ]
            },
            // {
            //     icon: 'mdi-account-lock',
            //     title: 'Laratrust',
            //     url: '/laratrust',
            //     subLinks: [
            //         {
            //             title: 'Permissions',
            //             // icon: 'mdi-account-key',
            //             url: '/laratrust/permissions',
            //         },
            //         {
            //             title: 'Roles',
            //             // icon: 'mdi-account-key',
            //             url: '/laratrust/roles',
            //         },
            //     ]
            // }
        ]
    },
    getters: {
        drawer_left(state) {
            return state.drawer_left
        },
        menu(state) {
            return state.menu
        }
    },
    mutations: {
        set_drawer_left(state, bool) {
            state.drawer_left = bool
        }
    },
    actions: {}
}