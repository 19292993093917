/* Middleware */
import auth from '@/middleware/auth';
import {show, create, update} from '@/middleware/permissions/customer'

export default [
    {
        path: '/users/customers',
        name: 'customers',
        meta: {
            layout: 'main',
            middleware: [auth, show]
        },
        component: () => import('@/views/customer/CustomersView')
    },
    {
        path: '/users/customers/create',
        name: 'customers.create',
        meta: {
            layout: 'main',
            middleware: [auth, create]
        },
        component: () => import('@/views/customer/CustomerCreateView')
    },
    {
        path: '/users/customers/:id/show',
        name: 'customers.show',
        meta: {
            layout: 'main',
            middleware: [auth, show]
        },
        component: () => import('@/views/customer/CustomerShowView')
    },
    {
        path: '/users/customers/:id/edit',
        name: 'customers.edit',
        meta: {
            layout: 'main',
            middleware: [auth, update]
        },
        component: () => import('@/views/customer/CustomerEditView')
    },
];