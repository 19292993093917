export default {

    // Company
    "company": 'Firma',
    "companies": 'Firmen',
    "company_phone": "Telefonnummer",
    "main_address": "Hauptadresse",
    "not_found": {
        "phone": "Diese Nummer ist unbekannt"
    },
    "search": {
        "label": "Suche...",
        "placeholder": "Suche...",
        "select": {
            "company_name": 'Firmenname',
            "company_name_short": 'Firmenname kurz',
            "company_phone": 'Telefonnummer ',
        },
    },
    "table": {
        "company_name": 'Firmenname',
        "company_name_short": 'Firmenname kurz',
        "company_phone": 'Telefonnummer',
        "customer": {
            "name": "Kundenname",
            "email": "Kunden-eMail ",
            "identification": "Kunden Personalnummer"
        },
    },

    // Time
    "opening_hours": "Öffnungszeiten",
    "closed": 'Geschlossen',
    "day_of_week": {
        'mo': 'Montag',
        'di': 'Dinstag',
        'mi': 'Mittwoch',
        'do': 'Donerstag',
        'fr': 'Freitag',
        'sa': 'Samstag',
        'so': 'Sonntag',
    },
    "day_of_week_short": {
        'mo': 'Mo',
        'di': 'Di',
        'mi': 'Mi',
        'do': 'Do',
        'fr': 'Fr',
        'sa': 'Sa',
        'so': 'So',
    },

    // Address
    "address": {
        "location": "Standort",
        "locations": "Standorte",
        "new_location": "Standort",
        "edit_location": "Standort ändern",
        "search": {
            "label": "Suche...",
            "placeholder": "Suche...",
            "select": {
                "country": 'Land',
                "city": 'Stadt',
                "postcode": 'Postleitzahl',
                "street": 'Straße',
                "is_main": "Hauptadresse",
            },
        },
        "table": {
            "country": 'Land',
            "city": 'Stadt',
            "postcode": 'Postleitzahl',
            "street": 'Straße und Hausnummer',
            "is_main": "Hauptadresse",
        },
        "show": {
            "country": 'Land',
            "postcode_city": 'Postleitzahl und Stadt',
            "city": 'Stadt',
            "postcode": 'Postleitzahl',
            "street": 'Straße und Hausnummer',
            "is_main": {
                "title": "Hauptadresse",
                "yes": "Ja",
                "no": "Nein",
            },
        }
    },

    // Person
    "person": {
        "title": "Ansprechpartner",
        "short_name": 'Standort',
        "search": {
            "label": "Suche...",
            "placeholder": "Suche...",
            "select": {
                "position": "Position",
                "first_name": "Vorname",
                "last_name": "Nachname",
                "email": "Email",
                "phone": "Telefonnummer",
                "identification": "Personalnummer"
            },
        },
        "table": {
            "short_name": 'Name des Standorts',
            "not_found": "Sie haben noch keinen registrierten Ansprechpartner",
            "position": "Position",
            "put_through": "Durchstellen",
            "first_name": "Vorname",
            "last_name": "Nachname",
            "gender": {
                "title": "Anrede",
                "female": "Frau",
                "male": "Mann",
                "divers": "Divers",
                "indefinite": "Unbestimmt"
            },
            "full_name": "Vorname Nachname",
            "birthday": "Geburtstag",
            "email": "Email",
            "phone": "Telefonnummer",
            "identification": "Personalnummer"
        },
        "show": {
            "position": "Position",
            "put_through": {
                "title": "Durchstellen",
                "no": "Nein",
                "yes": "Ja",
            },
            "first_name": "Vorname",
            "last_name": "Nachname",
            "gender": {
                "title": "Anrede",
                "female": "Frau",
                "male": "Mann",
                "divers": "Divers",
                "indefinite": "Unbestimmt"
            },
            "full_name": "Vorname Nachname",
            "birthday": "Geburtstag",
            "email": "Email",
            "phone": "Telefonnummer",
            "identification": "Personalnummer"
        },
    }
}
