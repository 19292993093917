import { http } from "../http_service";


/** PERMISSIONS */
export function permissions() {
    return http().get(`/permissions`);
}

/** PERMISSION CREATE */
export function permissionCreate(data) {
    return http().post(`/permissions`, data);
}

/** PERMISSIONS UPDATE */
export function permissionUpdate(data) {
    return http().put(`/permissions/${data.id}`, data);
}

/** PERMISSIONS DELETE */
export function permissionDelete(id) {
    return http().delete(`/permissions/${id}`);
}


/** ROLES */
export function roles() {
    return http().get(`/roles`);
}

/** ROLE CREATE */
export function roleCreate(data) {
    return http().post(`/roles`, data);
}

/** ROLE SHOW */
export function roleShow(id) {
    return http().get(`/roles/${id}/show`);
}

/** ROLE EDIT */
export function roleEdit(id) {
    return http().get(`/roles/${id}/edit`);
}

/** ROLE Update */
export function roleUpdate(data) {
    return http().put(`/roles/${data.id}`, data);
}

/** ROLE EDIT */
export function roleDelete(id) {
    return http().delete(`/roles/${id}`);
}