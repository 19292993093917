<template>
  <v-navigation-drawer app dark  v-model="drawer" style="z-index: 9999;" mobile-breakpoint="900">

    <template v-slot:prepend>
<!--      <v-list-item class="my-3">-->
<!--        <v-list-item-avatar color="primary">-->
<!--          <span class="white&#45;&#45;text headline">{{ user.first_name[0] }}{{ user.last_name[0] }}</span>-->
<!--        </v-list-item-avatar>-->

<!--        <v-list-item-content>-->
<!--          <v-list-item-title>{{ user.first_name }} {{ user.last_name }}</v-list-item-title>-->
<!--          <v-list-item-subtitle>{{ user.position }}</v-list-item-subtitle>-->
<!--        </v-list-item-content>-->
<!--      </v-list-item>-->

      <div class="pt-7 ml-n4">
        <v-img class="mx-auto mb-7" src="/assets/images/logo.png" max-width="100" />
      </div>
    </template>



    <v-divider class="mb-3"></v-divider>

    <ListGroup :links="links" />

  </v-navigation-drawer>
</template>

<script>
import ListGroup from "@/components/User/ListGroup";

export default {
  components: {ListGroup},
  data() {
    return {
      items: [],
      user: {},
      links: []
    }
  },
  created() {
    this.links = this.$store.getters.menu
    this.user = this.$store.getters.user
  },
  computed: {
    drawer: {
      get () {
        return this.$store.getters.drawer_left
      },
      set (val) {
        this.$store.commit('set_drawer_left', val)
      }
    }
  }
}
</script>
