export default {
    "not_found": "Sie haben noch keinen registrierten Kunden",
    "id": "ID",
    "uid": "UID",
    "crm_uid": "Mitarbeiter-ID in CRM System",
    "identification": "Personalnummer",
    "first_name": "Vorname",
    "last_name": "Nachname",
    "password": "Passwort",
    "email": "Email",
    "image": "Photo",
    "email_send": "Email an den Mitarbeiter senden",
    "position": "Mitarbeiter position",
    "full_name": "Vorname Nachname",
    "birthday": "Geburtstag",
    "is_blocked": "Mitarbeiter blockiert",
    "is_deleted": "Mitarbeiter gelöscht",
    "employees_online": "Mitarbeiter online",
    "contact_person": {
        "first_name": "Vorname",
        "last_name": "Nachname",
        "email": "Email",
        "position": "Position",
        "phone": "Telefonnummer",
    },
    "active_employees": "Nur aktive Mitarbeiter",
    "gender": {
        "title": "Anrede",
        "female": "Frau",
        "male": "Mann",
        "divers": "Divers",
        "indefinite": "Unbestimmt"
    },
    "buttons": {
        "avatar_create": "Avatar erstellen",
        "password_create": "Passwort erstellen",
        "save": "Speichern",
        "delete": "Löschen",
        "abort": "Abbrechen"
    },
    "titles": {
        "personal_data": "Persönlichen daten",
        "role_and_permissions": "Rolle und Berechtigungen",
        "settings": "Einstellungen",
        "password": "Passwort",
        "avatar": "Photo",
        "employee_delete": "Wollen Sie diese Mitarbeiter löschen?",
    },
    "status": {
        "active": "Aktiv",
        "not_active": "Inaktiv",
    },
    "search": {
        "label": "Suche...",
        "placeholder": "Suche...",
        "select": {
            "position": "Position",
            "first_name": "Vorname",
            "last_name": "Nachname",
            "email": "Email",
            "identification": "Personalnummer"
        },
    },
    "table": {},
    "show": {
        "identification": "Personalnummer",
        "first_name": "Vorname",
        "last_name": "Nachname",
        "email": "Email",
        "full_name": "Vorname Nachname",
        "birthday": "Geburtstag",
        "gender": {
            "title": "Anrede",
            "female": "Frau",
            "male": "Mann",
            "divers": "Divers",
            "indefinite": "Unbestimmt"
        },
    },
    "create": {
        "title": "Neuen Mitarbeiter erstellen",
        "employee_search_label": "Wählen Sie ein Mitarbeiter",
        "employee_search_info": "Suche nach Nachnamen",
        "identification": "Die ID-Nummer wird automatisch generiert",
        "main": {
            "title": "Main"
        },
        "errors": {
            "user_hast": "Dieser Benutzer wurde bereits hinzugefügt",
            "required_fields": "Alle erforderlichen Felder müssen ausgefüllt werden"
        }
    },
    "edit": {
        "title": "Mitarbeiterdaten ändern",
    },
    "crm": {
        "title": "Mitarbeiter CRM System",
        "buttons": {
            "employees": "Mitarbeiter",
            "role_and_permissions": "Rolle und Berechtigungen",
            "report": "Berichte"
        },
        "create": {
            "title": "Neuen Mitarbeiter erstellen",
        },
        "edit": {},
        "search": {
            "user": {
                "title": "Suche nach",
                "first_name": "Vorname",
                "last_name": "Nachname",
            },
            "employee_search_label": "Wählen Sie ein Mitarbeiter",
            "employee_search_info": "Suche nach Nachnamen",
        }
    }
}
