import * as Service from '@/services/report/report_service'

export default {
    namespace: true,
    state: {
        reports_employee: [],
        companies_for_reports: [],
        employees_for_reports: []
    },
    getters: {
        reports_employee(state) {
            return state.reports_employee
        },
        companies_for_reports(state) {
            return state.companies_for_reports
        },
        employees_for_reports(state) {
            return state.employees_for_reports
        }
    },
    mutations: {
        SET_REPORTS_EMPLOYEE(state, reports) {
            state.reports_employee = reports
        },
        SET_COMPANIES_FOR_REPORTS(state, companies) {
            state.companies_for_reports = companies
        },
        SET_EMPLOYEES_FOR_REPORTS(state, employees) {
            state.employees_for_reports = employees
        },
    },
    actions: {
        async reportsEmployees({commit}) {
            await Service.reportsEmployees().then(response => {
                commit('SET_REPORTS_EMPLOYEE', JSON.parse(response.data.reports.original))
                commit('SET_COMPANIES_FOR_REPORTS', JSON.parse(response.data.companies).data)
                commit('SET_EMPLOYEES_FOR_REPORTS', response.data.employees)
            })
        },
    }
}
