import {http, httpFile, httpDocument, httpDocumentFile} from "../http_service";

/** Categories **/
export function documentCategories() {
    return http().get(`/documents/categories`);
}

export function documentCategoryCreate(data) {
    return http().post(`/documents/categories`, data);
}

export function documentCategoryUpdate(data) {
    return http().put(`/documents/categories/${data.id}`, data);
}

export function documentCategoryDelete(id) {
    return http().delete(`/documents/categories/${id}`);
}


/** Documents **/
export function allDocuments() {
    return http().get(`/documents`);
}

export function customerDocuments(customer_id) {
    return http().get(`/documents/customer/${customer_id}`);
}

export function documentEdit(id) {
    return http().get(`/documents/${id}/edit`);
}

export function documentCreate(data) {
    return httpDocumentFile().post(`/documents`, data);
}

export function documentUpdate(data) {
    return http().put(`/documents/${data.id}`, data);
}

export function documentDelete(id) {
    return http().delete(`/documents/${id}`);
}
