import * as Service from '@/services/company/company_service.js'
import {companyCustomerAccessDataCreate} from "../../services/company/company_service";

export default {
    namespace: true,
    state: {
        company: {},
        companies: [],
        company_addresses: [],
        company_address: {},
        contact_persons: [],
        contact_person: {},
        customer_access_data: {},
        company_faq: {},
        company_faq_question: {},
    },
    getters: {
        companies(state) {
            return state.companies
        },
        company(state) {
            return state.company
        },
        company_address(state) {
            return state.company_address
        },
        company_addresses(state) {
            return state.company_addresses
        },
        company_contact_person(state) {
            return state.contact_person
        },
        company_customer_access_data(state) {
            return state.customer_access_data
        },
        company_faq(state) {
            return state.company_faq
        },
        company_faq_question(state) {
            return state.company_faq_question
        },
        contact_persons(state) {
            return state.contact_persons
        },
    },
    mutations: {
        SET_COMPANIES(state, companies) {
            state.companies = companies
        },
        SET_COMPANY(state, company) {
            state.companies.push(company)
        },
        DELETE_COMPANY(state, company) {
            let index = state.companies.findIndex(c => c.id === company.id)
            state.companies.splice(index, 1)
        },
        SET_COMPANY_ADDRESS(state, company_address) {
            state.company_address = company_address
        },
        SET_COMPANY_ADDRESS_AFTER_CREATE (state, company_address) {
            const company_index = state.companies.findIndex(company => company.id === parseInt(company_address.company_id))
            state.companies[company_index].addresses.push(company_address)
        },
        SET_COMPANY_ADDRESS_AFTER_UPDATE (state, company_address) {
            const company_index = state.companies.findIndex(company => company.id === parseInt(company_address.company_id))
            state.companies[company_index].addresses = state.companies[company_index].addresses.map(adr => {
                if (adr.id === parseInt(company_address.id)) {
                    return company_address
                }
                return adr
            })
        },
        SET_COMPANY_ADDRESS_AFTER_DELETE (state, company_address) {
            const company_index = state.companies.findIndex(company => company.id === parseInt(company_address.company_id))
            const addresses = state.companies[company_index].addresses
            const address_index = addresses.findIndex(ad => ad.id === company_address.is)
            addresses.splice(address_index, 1)
        },
        SET_COMPANY_CONTACT_PERSON(state, contact_person) {
            state.contact_person = contact_person
        },
        SET_COMPANY_CUSTOMER_ACCESS_DATA(state, cad) {
            state.customer_access_data = cad
        },
        SET_COMPANY_FAQ(state, faq) {
            state.company_faq = faq
        },
        SET_COMPANY_FAQ_QUESTION(state, question) {
            state.company_faq_question = question
        },
    },
    actions: {
        /** COMPANY ***/
        async companiesFromCustomer({commit, state}, customer_id) {
            await Service.companiesFromCustomer(customer_id).then(response => {
                commit('SET_COMPANIES', response.data.data)
            })
        },
        async companyCreate({commit, state}, data) {
            await Service.companyCreate(data).then(response => {
                commit('SET_COMPANY', response.data.data)
            })
        },
        async companyUpdate({commit, state}, data) {
            await Service.companyUpdate(data)
        },
        async companyDelete({commit, state}, company_id) {
            await Service.companyDelete(company_id).then(response => {
                commit('DELETE_COMPANY', response.data)
            })
        },

        /** ADDRESS ***/
        async companyAddressCreate({commit, state}, data) {
            await Service.companyAddressCreate(data).then(response => {
                commit('SET_COMPANY_ADDRESS_AFTER_CREATE', response.data.data)
                commit('SET_COMPANY_ADDRESS', response.data.data)
            })
        },
        async companyAddressUpdate({commit, state}, data) {
            await Service.companyAddressUpdate(data).then(response => {
                commit('SET_COMPANY_ADDRESS_AFTER_UPDATE', response.data.data)
                commit('SET_COMPANY_ADDRESS', response.data.data)
            })
        },
        async companyAddressDelete({commit, state}, address_id) {
            await Service.companyAddressDelete(address_id).then(response => {
                commit('SET_COMPANY_ADDRESS_AFTER_DELETE', response.data.data)
            })
        },

        /** Contact person ***/
        async companyContactPersonCreate({commit, state}, data) {
            await Service.companyContactPersonCreate(data).then(response => {
                commit('SET_COMPANY_CONTACT_PERSON', response.data.data)
            })
        },
        async companyContactPersonUpdate({commit, state}, data) {
            await Service.companyContactPersonUpdate(data).then(response => {
                commit('SET_COMPANY_CONTACT_PERSON', response.data.data)
            })
        },
        async companyContactPersonDelete({commit, state}, id) {
            await Service.companyContactPersonDelete(id)
        },

        /** Customer access data ***/
        async companyCustomerAccessDataCreate({commit, state}, data) {
            await Service.companyCustomerAccessDataCreate(data).then(response => {
                commit('SET_COMPANY_CUSTOMER_ACCESS_DATA', response.data.data)
            })
        },
        async companyCustomerAccessDataUpdate({commit, state}, data) {
            await Service.companyCustomerAccessDataUpdate(data).then(response => {
                commit('SET_COMPANY_CUSTOMER_ACCESS_DATA', response.data.data)
            })
        },
        async companyCustomerAccessDataDelete({commit, state}, id) {
            await Service.companyCustomerAccessDataDelete(id).then(response => {
                commit('SET_COMPANY_CUSTOMER_ACCESS_DATA', response.data.data)
            })
        },

        /** FAQ ***/
        async companyFAQ({commit, state}, company_id) {
            await Service.companyFAQ(company_id).then(response => {
                commit('SET_COMPANY_FAQ', response.data)
            })
        },
        async companyFAQUpdate({commit, state}, data) {
            await Service.companyFAQUpdate(data).then(response => {
                commit('SET_COMPANY_FAQ', response.data)
            })
        },

        /** FAQ QUESTION ***/
        async companyFaqQuestionCreate({commit, state}, data) {
            await Service.companyFaqQuestionCreate(data).then(response => {
                commit('SET_COMPANY_FAQ_QUESTION', response.data.data)
            })
        },
        async companyFaqQuestionUpdate({commit, state}, data) {
            await Service.companyFaqQuestionUpdate(data).then(response => {
                commit('SET_COMPANY_FAQ_QUESTION', response.data.data)
            })
        },
        async companyFaqQuestionDelete({commit, state}, id) {
            await Service.companyFaqQuestionDelete(id).then(response => {
                commit('SET_COMPANY_FAQ_QUESTION', response.data.data)
            })
        },
    }
}
