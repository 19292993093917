import Vue from 'vue'
import VueRouter from 'vue-router'
import options from "../options";

Vue.use(VueRouter)

import auth from "@/middleware/auth";

/* Routes */
import Auth from './_auth_router'
// import User from './user/_user_router'
import UserCrm from './user/_user_crm_router'
import Company from './_company_router'
import Customer from './customers/_customer_router'
import CustomerCrm from './customers/_customer_crm_router'
import Employee from './employees/_employee_router'
import EmployeeCrm from './employees/_employee_crm_router'
// import Laratrust from './_laratrust_router'
import Documents from './documents/_document_router'

import Notice from '@/modules/notice/router/index'

const routes = [
    ...Auth,
    ...Customer,
    ...CustomerCrm,
    ...Company,
    ...Employee,
    ...EmployeeCrm,
    // ...User,
    ...UserCrm,
    // ...Laratrust,
    ...Documents,
    ...Notice,
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/Home')
    },
    {
        path: '*',
        meta: {
            layout: 'empty'
        },
        component: () => import('@/views/NotFoundView')
    }
]


const router = new VueRouter({
    mode: options.route_mode,
    base: process.env.BASE_URL,
    routes
})

export default router

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) {
        return context.next;
    }

    return (...parameter) => {
        context.next(...parameter);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware})
    }
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const context = {from, next, router, to};
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({...context, next: nextMiddleware})
    }
    return next();
});
