<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      min-width="300"
      offset-y
      transition="slide-y-reverse-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn plain dark v-bind="attrs" v-on="on" :ripple="false">
        {{ user.first_name }} <v-icon>{{ menu ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar color="primary">
            <span class="white--text headline">{{ user.first_name[0] }}{{ user.last_name[0] }}</span>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user.last_name }}.{{ user.first_name }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.position }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon :class="fav ? 'primary--text' : ''" @click="fav = !fav">
              <v-icon size="28">mdi-shield-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
<!--        <v-list-item link to="/settings">-->
<!--          <v-list-item-action>-->
<!--            <v-icon class="mx-top-menu-action">mdi-tune-vertical</v-icon>-->
<!--          </v-list-item-action>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>Einstellung</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
<!--        <v-list-item>-->
<!--          <v-list-item-action>-->
<!--            <v-switch-->
<!--                v-model="white_theme"-->
<!--                @change="switchTheme()"-->
<!--                color="success"-->
<!--            ></v-switch>-->
<!--          </v-list-item-action>-->
<!--          <v-list-item-title>Weißes Thema</v-list-item-title>-->
<!--        </v-list-item>-->
        <v-list-item tag="a" @click="logout" class="primary mt-3 mb-n2" dark>
          <v-list-item-action>
            <v-icon class="mx-top-menu-action">mdi-logout-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('auth.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
      white_theme: true,
      user: null
    }
  },
  created() {
    this.white_theme = !this.$vuetify.theme.dark
    this.user = this.$store.getters.user
  },
  methods: {
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.white_theme = !this.$vuetify.theme.dark
    },
    logout() {
      this.$store.dispatch('signOut')
    }
  }
}
</script>
