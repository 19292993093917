import store from '@/store'

function showPermission({next}) {
  if (store.getters.auth_permissions.find((per) => per === 'permission-show') === undefined) {
    return next({
      name: 'dashboard'
    });
  }
  return next();
}

function showRole({next}) {
  if (store.getters.auth_permissions.find((per) => per === 'role-show') === undefined) {
    return next({
      name: 'dashboard'
    });
  }
  return next();
}

function createRole({next}) {
  if (store.getters.auth_permissions.find((per) => per === 'role-create') === undefined) {
    return next({
      name: 'dashboard'
    });
  }
  return next();
}

function updateRole({next}) {
  if (store.getters.auth_permissions.find((per) => per === 'role-update') === undefined) {
    return next({
      name: 'dashboard'
    });
  }
  return next();
}

export { showPermission, showRole, createRole, updateRole }
