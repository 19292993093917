import * as Service from '@/services/employee/employee_crm_service'
import moment from "moment";

export default {
    namespace: true,
    state: {
        employee_crm: {},
        employees_crm: [],
        employees_crm_dashboard: [],
    },
    getters: {
        employeesCrm(state) {
            return state.employees_crm
        },
        employeesCrmDashboard(state) {
            return state.employees_crm_dashboard
        },
        employeeCrm(state) {
            return state.employee_crm
        }
    },
    mutations: {
        SET_EMPLOYEES_CRM(state, employees) {
            state.employees_crm = employees
        },
        SET_EMPLOYEES_CRM_DASHBOARD(state, employees) {
            state.employees_crm_dashboard = employees
        },
        SET_EMPLOYEE_CRM_DASHBOARD(state, id) {
            let user = state.employees_crm.data.find(e => e.id === id)
            user.authorized = {}
            user.authorized.created_at = moment().format('DD.MM.YYYY H:mm')
            state.employees_crm_dashboard.push(user)
        },
        SET_EMPLOYEE_CRM_DELETE_DASHBOARD(state, id) {
            state.employees_crm_dashboard = state.employees_crm_dashboard.filter(e => e.id !== id)
        },
        SET_EMPLOYEE_CRM(state, employee) {
            state.employee_crm = employee
        }
    },
    actions: {
        /* Employees */
        async employeesCrm({commit, state}, page) {
            await Service.employeesCrm(page).then(response => {
                commit('SET_EMPLOYEES_CRM', response.data)
            })
        },

        async employeesActiveCrm({commit, state}, page) {
            await Service.employeesActiveCrm(page).then(response => {
                commit('SET_EMPLOYEES_CRM_DASHBOARD', response.data)
            })
        },

        /* Employees search */
        async employeeCrmSearch({commit, state}, data) {
            await Service.employeeCrmSearch(data).then(response => {
                commit('SET_EMPLOYEES_CRM', response.data)
            })
        },

        async employeeCrmCreate({commit, state}, data) {
            await Service.employeeCrmCreate(data)
        },

        async employeeCrmEdit({commit, state}, id) {
            await Service.employeeCrmEdit(id).then(response => {
                commit('SET_EMPLOYEE_CRM', response.data)
            })
        },

        async employeeCrmUpdate({commit, state}, data) {
            await Service.employeeCrmUpdate(data)
        },

        async employeeCrmShow({commit, state}, id) {
            await Service.employeeCrmShow(id).then(response => {
                commit('SET_EMPLOYEE_CRM', response.data)
            })
        },

        async employeeCrmDelete({commit, state}, id) {
            await Service.employeeCrmDelete(id)
        }
    }
}
