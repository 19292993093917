import * as Service from '@/services/laratrust/laratrust_service'

export default {
    namespace: true,
    state: {
        permission: {},
        permissions: [],
        role: {},
        roles: [],
    },
    getters: {
        permission_laratrust(state) {
            return state.permission
        },
        permissions_laratrust(state) {
            return state.permissions
        },
        role_laratrust(state) {
            return state.role
        },
        roles_laratrust(state) {
            return state.roles
        }
    },
    mutations: {
        SET_ROLE_LARATRUST(state, data) {
            state.role = data
        },
        SET_ROLES_LARATRUST(state, data) {
            state.roles = data
        },
        SET_PERMISSION_LARATRUST(state, data) {
            state.permission = data
        },
        SET_PERMISSIONS_LARATRUST(state, data) {
            state.permissions = data
        }
    },
    actions: {
        /* Permissions */
        async permissionsLaratrust({commit}) {
            await Service.permissions().then(response => {
                commit('SET_PERMISSIONS_LARATRUST', response.data)
            })
        },

        /* Permission Create */
        async permissionCreateLaratrust({commit}, data) {
            await Service.permissionCreate(data).then(response => {
                commit('SET_PERMISSIONS_LARATRUST', response.data)
            })
        },

        /* Permission Update */
        async permissionUpdateLaratrust({commit}, data) {
            await Service.permissionUpdate(data).then(response => {
                commit('SET_PERMISSIONS_LARATRUST', response.data)
            })
        },

        /* Permission Delete */
        async permissionDeleteLaratrust({commit}, id) {
            await Service.permissionDelete(id).then(response => {
                commit('SET_PERMISSIONS_LARATRUST', response.data)
            })
        },


        /* Roles */
        async rolesLaratrust({commit}) {
            await Service.roles().then(response => {
                commit('SET_ROLES_LARATRUST', response.data)
            })
        },

        /* Role create */
        async roleCreateLaratrust({commit}, data) {
            await Service.roleCreate(data).then(response => {
                commit('SET_ROLES_LARATRUST', response.data)
            })
        },

        /* Role Show */
        async roleShowLaratrust({commit}, id) {
            return await Service.roleShow(id)
        },

        /* Role Edit */
        async roleEditLaratrust({commit}, id) {
            return await Service.roleEdit(id)
        },

        /* Role Update */
        async roleUpdateLaratrust({commit}, data) {
            return await Service.roleUpdate(data)
        },

        /* Role Delete */
        async roleDeleteLaratrust({commit}, id) {
            return await Service.roleDelete(id)
        },

    }
}