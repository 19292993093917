import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import System from "./system_store";
import Auth from './auth/auth_store'
import User from './user/user_store'
import Customers from './customer/customer_store'
import CustomersGateway from './customer/customer_gateway_store'
import CustomerGatewayContactPerson from './customer/customer_gateway_contact_person'
import Companies from './company/company_store'
import Employees from './employee/employee_store'
import EmployeesCrm from './employee/employee_crm_store'
import Permissions from './laratrust/laratrust_store'
import PermissionsEmployee from './laratrust/laratrust_employee_store'
import Reports from './report/report_store'
import Documents from './document/document_store'

import Notice from '@/modules/notice/store/store'

export default new Vuex.Store({
  modules: {
    System,
    Auth,
    User,
    Customers,
    CustomersGateway,
    CustomerGatewayContactPerson,
    Companies,
    Employees,
    EmployeesCrm,
    Permissions,
    PermissionsEmployee,
    Reports,
    Documents,
    Notice
  },
  state: {},
  mutations: {},
  actions: {}
})
