import auth from '@/middleware/auth';
import {show, create, update} from "@/middleware/permissions/document_category";

export default [
    {
        path: 'categories',
        name: 'documents.categories',
        meta: {
            layout: 'main',
            middleware: [auth, show]
        },
        component: () => import('@/views/document/category/DocumentCategories'),
    },
    {
        path: 'categories/create',
        name: 'documents.category.create',
        meta: {
            layout: 'main',
            middleware: [auth, create]
        },
        component: () => import('@/views/document/category/DocumentCategoryCreate'),
    },
    {
        path: 'categories/:id/edit',
        name: 'documents.category.edit',
        meta: {
            layout: 'main',
            middleware: [auth, update]
        },
        component: () => import('@/views/document/category/DocumentCategoryEdit'),
    }

];
