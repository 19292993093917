import { http } from "../http_service";


/** EMPLOYEES */
export function employees(page) {
    return http().get(`/employees-service?page=${page}`);
}

/** EMPLOYEE SHOW */
export function employeeShow(id) {
    return http().get(`/employees/${id}`);
}

/** EMPLOYEE SEARCH */
export function employeeSearch(data) {
    return http().post(`/employees-service/search`, data);
}

/** EMPLOYEE EDIT */
export function employeeEdit(id) {
    return http().get(`/employees-service/${id}/edit`);
}

/** EMPLOYEE CREATE */
export function employeeCreate(data) {
    return http().post(`/employees-service`, data);
}

/** EMPLOYEE UPDATE */
export function employeeUpdate(data) {
    return http().put(`/employees-service/${data.id}`, data);
}

/** EMPLOYEE DELETE */
export function employeeDelete(id) {
    return http().delete(`/employees-service/${id}`);
}

/** AVATAR DELETE */
export function employeeAvatarDelete(data) {
    return http().post(`/employees-service/${data.id}/avatar-remove`, data);
}
