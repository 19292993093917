import '@fortawesome/fontawesome-free/css/all.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';


Vue.use(Vuetify);

const theme = {
    background: '#f5f5f5',
    primary: '#fd9843',
    secondary: '#9C27b0',
    accent: '#ff3367',

    success: '#126d67',
    info: '#0aa2c0',
    warning: '#FFD100',
    error: '#dd4141',

    header_bg: '#444444',
    drawer_bg: '#444444',
}

export default new Vuetify({
    icons: {
        iconfont: 'md' || 'fa'
    },
    theme: {
        dark: false,
        themes: {
            dark: {
                primary: '#126d67',
                accent: '#ff3367',
                secondary: '#fd9843',

                success: '#2e9858',
                info: '#0aa2c0',
                warning: '#FFD100',
                error: '#dd4141',

                // background: '#f5f5f5',
                // header_bg: '#444444',
                // drawer_bg: '#444444',
            },
            light: {
                primary: '#fc8b5d', //f8692f
                secondary: '#126d67',
                accent: '#ff6f35',

                success: '#2e9858',
                info: '#0aa2c0',
                warning: '#FFD100',
                error: '#dd4141',

                background: '#f5f5f5',
                header_bg: '#363636',
                drawer_bg: '#444444',
            },
        },
    },
});
