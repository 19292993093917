import * as Service from '@/services/user/user_service'

export default {
    namespace: true,
    state: {
        user: {},
        users: [],
        roles: [],
        permissions: [],
    },
    getters: {
        user(state) {
            return state.user
        },
        users(state) {
            return state.users
        },
        roles(state) {
            return state.roles
        },
        permissions(state) {
            return state.permissions
        }
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user
        },
        SET_USERS(state, users) {
            state.users = users
        },
        SET_ROLES(state, data) {
            state.roles = data
        },
        SET_PERMISSIONS(state, data) {
            state.permissions = data
        }
    },
    actions: {
        /* Users */
        async users({commit, state}, page) {
            // if (state.customers.length === 0) {
            await Service.users(page).then(response => {
                commit('SET_USERS', response.data)
            })
            // }
        },

        /* User update */
        // async userUpdate({commit}, data) {
        //     await Service.userUpdate(data, data.user_id).then(response => {
        //         commit('SET_USER', response.data.data)
        //         commit('SET_LANGUAGE', response.data.data.language)
        //     })
        // },

        async userSearch({commit, state}, data) {
            await Service.userSearch(data).then(response => {
                commit('SET_USERS', response.data)
            })
        },

        async userCreate({commit, state}, data) {
            await Service.userCreate(data)
        },

        async userCreateGet({commit}) {
            await Service.userCreateGet().then(response => {
                commit('SET_ROLES', response.data.roles)
                commit('SET_PERMISSIONS', response.data.permissions)
            })
        },

        async userEdit({commit, state}, id) {
            await Service.userEdit(id).then(response => {
                commit('SET_USER', response.data.data)
            })
        },

        async userUpdate({commit, state}, data) {
            await Service.userUpdate(data)
        },

        async userShow({commit, state}, id) {
            await Service.userShow(id).then(response => {
                commit('SET_USER', response.data)
            })
        },

        async userDelete({commit, state}, id) {
            await Service.userDelete(id)
        }
    }
}