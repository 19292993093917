/* Middleware */
import auth from '@/middleware/auth';

export default [
    {
        path: 'reports',
        name: 'crm.reports',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/employee/crm/report/ReportEmployeeCRMView'),
    }

];